<template>
  <div>
    <Notify ref="myNotify" :details="error_msg"></Notify>
    <div style="padding-top: 125px" class="text-center">
      <img
        width="150"
        height="150"
        src="@/assets/img/alborj/avatar-login.png"
        alt="avatar"
      />
    </div>
    <h1 class="h-title-login pl-2 mb-9">{{ $t("auth.login") }}</h1>

    <v-form @submit="handleLogin" ref="loginForm">
      <div class="row">
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.email"
            type="email"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Email Address')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.email"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            v-model="form.password"
            :rules="rules.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            lazy-validation
            @click:append="show = !show"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Password')"
            class="font-size-input placeholder-lighter text-light-input"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <div class="div_btn">
            <v-btn
              class="submit_btn"
              :ripple="false"
              :loading="loading"
              type="submit"
            >
              <!-- @submit="handleLogin()" -->
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                >
                </v-progress-circular>
                <!-- {{ $t("general.loading") }} -->
              </span>
              {{ $t("auth.login") }}
            </v-btn>
            <div class="account text-right">
              <!-- <router-link to="/admin/login" class="login-link">
                {{ $t("auth.Keep me logged in") }}
              </router-link> -->
              <router-link to="/reset_password" class="login-link">
                <span class="text-danger">{{
                  $t("auth.forgot password ?")
                }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import Notify from "../Components/Old/Notify.vue";
export default {
  components: { Notify },
  data() {
    return {
      valid: false,
      show: false,
      form: {
        email: "",
        paswword: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 6) || "minimum 6 characters",
        ],
      },
      errors: {},
      error_msg: {
        msg: null,
        type: null,
      },
      loading: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.loginForm.validate();
    },
    handleLogin(e) {
      e.preventDefault();
      // this.error_msg.msg = "";
      this.loading = true;
      if (this.validate()) {
        this.$store
          .dispatch("auth/login", { form: this.form, type: "admin" })
          .then(
            (response) => {
              // console.log(response);
              this.loading = false;
              console.log(response);
              if (response.data.original.permissions.length > 0) {
                // if (localStorage.hasOwnProperty("location")) {
                //   let location = localStorage.getItem("location");
                //   this.$router.push(location);
                // } else {
                // }
                console.log(document.referrer);
                if (document.referrer == "") {
                  this.$router.push({ name: "Default" });
                } else {
                  this.$router.go(-1);
                }
              } else {
                this.error_msg.msg =
                  this.$i18n.t("sorry you dont have any permission") + " !";
                this.error_msg.type = "Danger";
              }
            },
            (error) => {
              console.log(error);
              this.loading = false;
              if (error && error.response.status == 422) {
                this.errors = error.response.data.errors;
                this.error_msg = {
                  msg:
                    (error.response && error.response.data.message) ||
                    error.message,
                  type: "Danger",
                };
              } else {
                this.error_msg = {
                  msg:
                    (error.response && error.response.data.message) ||
                    error.message,
                  type: "Danger",
                };
              }
            }
          );
      } else {
        this.loading = false;
        this.error_msg = {
          msg: this.$i18n.t("auth.Please Check errors and try again"),
          type: "Danger",
        };
        return;
      }
    },
  },
  mounted() {
    // console.log(this.$router);
    // console.log(this.$route);
    if (this.$store.state.auth.loggedIn) {
      this.$router.push({ name: "Default" });
    }
    document.title = this.$i18n.t("auth.login");
  },
};
</script>
