<template>
  <div class="content">
    <Notify ref="myNotify" :details="error_msg"></Notify>

    <h1 class="h-title-login pl-2 mb-9">
      {{ $t("auth.Email Address") }}
    </h1>

    <v-form @submit="handleLogin" ref="loginForm">
      <div class="row">
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.email"
            type="email"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Email Address')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.email"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <div class="div_btn">
            <v-btn
              class="submit_btn"
              :ripple="false"
              :loading="loading"
              type="submit"
            >
              <!-- @submit="handleLogin()" -->
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                >
                </v-progress-circular>
                <!-- {{ $t("general.loading") }} -->
              </span>
              {{ $t("auth.send") }}
            </v-btn>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import AuthService from "../../services/auth.service";
import Notify from "../Components/Old/Notify.vue";
export default {
  components: { Notify },
  data() {
    return {
      valid: false,
      show: false,
      form: {
        email: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      errors: {},
      error_msg: {
        msg: null,
        type: null,
      },
      loading: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.loginForm.validate();
    },
    handleLogin(e) {
      e.preventDefault();
      // this.error_msg.msg = "";
      this.loading = true;
      if (this.validate()) {
        return AuthService.reset_password(this.form).then(
          (response) => {
            console.log(response);
            console.log(response.data.url);
            this.loading = false;
            this.error_msg.msg = response.data.message;
            this.error_msg.type = "Success";
            // this.$router.push({ path: response.data.url });
          },
          (error) => {
            console.log(error);
            this.loading = false;
            if (error && error.response.status == 422) {
              this.errors = error.response.data.errors;
              this.error_msg.msg =
                (error.response && error.response.data.message) ||
                error.message;
              error.toString();
              this.error_msg.type = "Danger";
            } else {
              this.error_msg.msg =
                (error.response && error.response.data.message) ||
                error.message;
              error.toString();
              this.error_msg.type = "Danger";
            }
          }
        );
      } else {
        this.loading = false;
        this.error_msg.msg = this.$i18n.t(
          "auth.Please Check errors and try again."
        );
        this.error_msg.type = "Danger";
        return;
      }
    },
  },
  mounted() {
    document.title = this.$i18n.t("auth.Reset Password");
  },
};
</script>
<style lang="css">
.login {
  height: 800px;
}
.login .form {
  height: 100%;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  text-align: center;
}
.content {
  padding-top: 50%;
}
</style>
