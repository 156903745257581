<template>
  <div class="text-center">
    <h1 class="pl-2 mt-10">Pleas Wait ......</h1>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      error_msg: {
        msg: "",
        type: "",
      },
      loading: false,
    };
  },
  methods: {
    loginGoogle() {
      this.$store
        .dispatch("auth/loginGoogleCallback", this.$route.query.code)
        .then(
          (response) => {
            // console.log(response);
            // alert("ok");
            if (response.register_form == 1) {
              // this mean there is inquery is requierd without answer
              localStorage.setItem("register-form", 1);
              var route = this.$router;
              setTimeout(() => {
                route.push("/form");
              }, 1000);
            } else {
              if (response.type == 1) {
                // mean login
                if (document.referrer == "") {
                  this.$router.push({ name: "Cycles" });
                } else {
                  this.$router.go(-1);
                }
              } else {
                // mean register
                this.$router.push({ name: "Cycles" });
              }
            }
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this.error_msg.msg =
              (error.response && error.response.data.message) || error.message;
            error.toString();
            this.error_msg.type = "Danger";
          }
        );
    },
  },
  mounted() {
    this.loginGoogle();
  },
};
</script>
