v-text-field<template>
  <div>
    <Notify ref="myNotify" :details="error_msg"></Notify>
    <h1 class="h-title pl-2">{{ $t("auth.Create Account") }}</h1>
    <div class="social">
      <div class="row">
        <div class="col-12 col-sm-6">
          <v-btn
            depressed
            :ripple="false"
            class="btn-social"
            color="transparent"
            @click="loginGoogle"
            style="text-transform: capitalize"
            :loading="loading_google"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="gray"
              ></v-progress-circular>
            </span>
            <!-- <i class="fab fa-google"></i> -->
            <img src="@/assets/img/alborj/search 1.png" alt="" />
            <p>{{ $t("auth.Sign Up With Google") }}</p>
          </v-btn>
        </div>
        <div class="col-12 col-sm-6">
          <v-btn
            depressed
            :ripple="false"
            class="btn-social"
            color="transparent"
            :loading="loading_facebook"
            @click="loginFacebook"
            style="text-transform: capitalize"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="gray"
              ></v-progress-circular>
            </span>
            <img src="@/assets/img/alborj/facebook 1.png" alt="" />
            <p>{{ $t("auth.Sign Up With Facebook") }}</p>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="break-form">- {{ $t("auth.OR") }} -</div>
    <v-form ref="loginForm">
      <div class="row">
        <div class="col-lg-12 pt-0">
          <!-- <v-text-field
            outlined
            v-model="form.company_name"
            :placeholder="$t('Company Name')"
            :rules="[(v) => !!v || $t('company name is required')]"
            lazy-validation
            color="rgba(0,0,0,.6)"
            light
            class="font-size-input placeholder-lighter text-light-input"
          >
          </v-text-field> -->
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.first_name"
            :error-messages="errors.first_name"
            type="text"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('First Name')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.first_name"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            outlined
            v-model="form.last_name"
            :placeholder="$t('Last Name')"
            :rules="[(v) => !!v || $t('last name is required')]"
            lazy-validation
            color="rgba(0,0,0,.6)"
            light
            class="font-size-input placeholder-lighter text-light-input"
          >
          </v-text-field>
        </div>

        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.email"
            :error-messages="errors.email"
            type="email"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Email Address')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.email"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            v-model="form.password"
            :rules="rules.password"
            :error-messages="errors.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            lazy-validation
            @click:append="show = !show"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Password')"
            class="font-size-input placeholder-lighter text-light-input"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.password_confirmation"
            :error-messages="errors.password_confirmation"
            :append-icon="show_cpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show_cpass ? 'text' : 'password'"
            lazy-validation
            @click:append="show_cpass = !show_cpass"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Repeat Password')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.password_confirmation"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.phone"
            type="text"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Phone Number')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.phone"
            :error-messages="errors.phone"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <div class="div_btn">
            <v-btn
              class="submit_btn"
              :ripple="false"
              :loading="loading"
              @click="handleLogin()"
            >
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                >
                </v-progress-circular>
                <!-- {{ $t("general.loading") }} -->
              </span>
              {{ $t("auth.Create Account") }}
            </v-btn>
            <div class="account">
              {{ $t("auth.Already have an account?") }}
              <router-link to="/login" class="text-decoration-none">
                <span class="text-danger">{{ $t("auth.Log in") }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import Notify from "../Components/Old/Notify.vue";
export default {
  components: { Notify },
  data() {
    return {
      loading_google: false,
      loading_facebook: false,
      error_msg: {
        msg: null,
        type: null,
      },
      valid: false,
      show: false,
      show_cpass: false,
      form: {
        email: "",
        password: "",
        password_confirmation: "",
        first_name: "",
        last_name: "",
        // company_name: "",
        phone: "",
        admin: 0,
      },
      rules: {
        first_name: [(v) => !!v || this.$i18n.t("first name is required")],
        last_name: [(v) => !!v || this.$i18n.t("last name is required")],
        phone: [(v) => !!v || this.$i18n.t("auth.Phone Number is required")],
        email: [
          (v) => !!v || this.$i18n.t("auth.E-mail is required"),
          (v) => /.+@.+/.test(v) || this.$i18n.t("auth.E-mail must be valid"),
        ],
        password: [
          (v) => !!v || this.$i18n.t("auth.Password is required"),
          (v) =>
            (v && v.length >= 6) || this.$i18n.t("auth.minimum 6 characters"),
        ],
        password_confirmation: [
          (v) => !!v || this.$i18n.t("auth.Password Confirmation is required"),
          (v) =>
            v == this.form.password ||
            this.$i18n.t(
              "auth.Password and password confirmation does not match"
            ),
        ],
      },
      errors: {},
      loading: false,
    };
  },
  methods: {
    validate() {
      return this.$refs.loginForm.validate();
    },
    handleLogin() {
      console.log(this.form);
      this.loading = true;
      if (this.validate()) {
        this.error_msg.msg = "";
        this.$store.dispatch("auth/register", this.form).then(
          (response) => {
            this.loading = false;
            // console.log(response);
            localStorage.setItem("user", JSON.stringify(response.user));
            localStorage.setItem("accessToken", JSON.stringify(response.token));
            if (response.is_admin == 0) {
              localStorage.setItem(
                "is_admin",
                JSON.stringify(response.is_admin)
              );
              this.$store.commit("auth/loginSuccess", {
                user: response.user,
                is_admin: response.is_admin,
              });
              localStorage.setItem("register-form", 1);
              this.$router.push({ name: "RegisterForm" });
            } else {
              this.$router.push({ name: "Default" });
            }
          },
          (error) => {
            console.log(error);
            this.loading = false;
            if (error && error.response.status == 422) {
              this.errors = error.response.data.errors;
              this.error_msg.msg =
                (error.response && error.response.data.message) ||
                error.message;
              error.toString();
              this.error_msg.type = "Danger";
            } else {
              this.error_msg.msg =
                (error.response && error.response.data.message) ||
                error.message;
              error.toString();
              this.error_msg.type = "Danger";
            }
          }
        );
      } else {
        this.loading = false;
        this.error_msg = this.$i18n.t(
          "auth.Please Check errors and try again."
        );
        this.error_msg.type = "Danger";
        return;
      }
    },
    loginGoogle() {
      this.loading_google = true;
      this.$store.dispatch("auth/loginGoogle").then(
        (response) => {
          // console.log(response);
          this.loading_google = false;
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        },
        (error) => {
          console.log(error);
          this.loading_google = false;
          this.error_msg.msg =
            (error.response && error.response.data.message) || error.message;
          error.toString();
          this.error_msg.type = "Danger";
        }
      );
    },
    loginFacebook() {
      this.loading_facebook = true;
      this.$store.dispatch("auth/loginFacebook").then(
        (response) => {
          this.loading_facebook = false;
          // console.log(response);
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        },
        (error) => {
          console.log(error);
          this.loading_facebook = false;
          this.error_msg.msg =
            (error.response && error.response.data.message) || error.message;
          error.toString();
          this.error_msg.type = "Danger";
        }
      );
    },
  },
  mounted() {
    document.title = this.$i18n.t("auth.register");
  },
};
</script>
