<template>
  <div>
    <Notify ref="myNotify" :details="error_msg"></Notify>
    <div style="padding-top: 125px" class="text-center">
      <img
        width="150"
        height="150"
        src="@/assets/img/alborj/avatar-login.png"
        alt="avatar"
      />
    </div>
    <h1 class="h-title-login pl-2">{{ $t("auth.login") }}</h1>
    <div class="social">
      <div class="row">
        <div class="col-12 col-sm-6">
          <v-btn
            depressed
            :ripple="false"
            class="btn-social"
            color="transparent"
            @click="loginGoogle"
            style="text-transform: capitalize"
            :loading="loading_google"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="gray"
              ></v-progress-circular>
            </span>
            <img src="@/assets/img/alborj/search 1.png" alt="" />
            <p>{{ $t("auth.Sign Up With Google") }}</p>
          </v-btn>
        </div>
        <div class="col-12 col-sm-6">
          <v-btn
            depressed
            :ripple="false"
            class="btn-social"
            color="transparent"
            :loading="loading_facebook"
            @click="loginFacebook"
            style="text-transform: capitalize"
          >
            <span slot="loader">
              <v-progress-circular
                style="width: 20px; height: 20px"
                indeterminate
                color="gray"
              ></v-progress-circular>
            </span>
            <img src="@/assets/img/alborj/facebook 1.png" alt="" />
            <p>{{ $t("auth.Sign Up With Facebook") }}</p>
          </v-btn>
        </div>
      </div>
    </div>
    <div class="break-form">- {{ $t("auth.OR") }} -</div>
    <v-form @submit="handleLogin" ref="loginForm">
      <div class="row">
        <div class="col-lg-12 pt-0">
          <v-text-field
            :rules="rules.email"
            type="email"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Email Address')"
            class="font-size-input placeholder-lighter text-light-input"
            v-model="form.email"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <v-text-field
            v-model="form.password"
            :rules="rules.password"
            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show ? 'text' : 'password'"
            lazy-validation
            @click:append="show = !show"
            outlined
            color="rgba(0,0,0,.6)"
            :placeholder="$t('auth.Password')"
            class="font-size-input placeholder-lighter text-light-input"
          >
          </v-text-field>
        </div>
        <div class="col-lg-12 pt-0">
          <div class="div_btn">
            <v-btn
              class="submit_btn"
              :ripple="false"
              :loading="loading"
              type="submit"
            >
              <!-- @submit="handleLogin()" -->
              <span slot="loader">
                <v-progress-circular
                  style="width: 20px; height: 20px"
                  indeterminate
                  color="white"
                >
                </v-progress-circular>
                <!-- {{ $t("general.loading") }} -->
              </span>
              {{ $t("auth.login") }}
            </v-btn>
            <!-- <div class="account d-flex justify-content-between"> -->
            <div class="account">
              <v-row>
                <v-col cols="7">
                  <!-- {{ $t("Do you have an account?") }} -->
                  <router-link to="/sign-up" class="login-link">
                    {{ $t("new account") }}
                  </router-link>
                </v-col>
                <v-col :class="classLang" cols="5">
                  <router-link to="/reset_password" class="login-link">
                    <span class="text-danger password">{{
                      $t("auth.forgot password ?")
                    }}</span>
                  </router-link>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>
import Notify from "../Components/Old/Notify.vue";
export default {
  components: { Notify },
  data() {
    return {
      loading_google: false,
      loading_facebook: false,
      valid: false,
      show: false,
      form: {
        email: "",
        paswword: "",
      },
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) => (v && v.length >= 6) || "minimum 6 characters",
        ],
      },
      errors: {},
      error_msg: {
        msg: null,
        type: null,
      },
      loading: false,
    };
  },
  computed: {
    classLang() {
      return {
        "text-right": this.$vuetify.rtl,
        "text-left": !this.$vuetify.rtl,
      };
    },
  },
  methods: {
    validate() {
      return this.$refs.loginForm.validate();
    },
    handleLogin(e) {
      e.preventDefault();
      // this.error_msg.msg = "";
      this.loading = true;
      if (this.validate()) {
        this.$store
          .dispatch("auth/login", { form: this.form, type: "customer" })
          .then(
            (response) => {
              // console.log(response);
              this.loading = false;
              if (response.is_admin == 0) {
                if (localStorage.hasOwnProperty("location")) {
                  let location = localStorage.getItem("location");
                  this.$router.push(location);
                } else {
                  console.log(document.referrer);
                  if (response.register_form == 1) {
                    // this mean there is inquery is requierd without answer
                    localStorage.setItem("register-form", 1);
                    var route = this.$router;
                    setTimeout(() => {
                      route.push("/form");
                    }, 1000);
                  } else {
                    if (document.referrer == "") {
                      this.$router.push({ name: "Cycles" });
                    } else {
                      this.$router.go(-1);
                    }
                  }
                }
              } else {
                this.$router.push({ name: "Login-Admin" });
              }
            },
            (error) => {
              console.log(error);
              this.loading = false;
              if (error && error.response.status == 422) {
                this.errors = error.response.data.errors;
                this.error_msg = {
                  msg:
                    (error.response && error.response.data.message) ||
                    error.message,
                  // error.toString(),
                  type: "Danger",
                };
              } else {
                this.error_msg = {
                  msg:
                    (error.response && error.response.data.message) ||
                    error.message,
                  type: "Danger",
                };
              }
            }
          );
      } else {
        this.loading = false;
        this.error_msg = {
          msg: this.$i18n.t("auth.Please Check errors and try again"),
          type: "Danger",
        };
        return;
      }
    },
    loginGoogle() {
      this.loading_google = true;
      this.$store.dispatch("auth/loginGoogle").then(
        (response) => {
          // console.log(response);
          this.loading_google = false;
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        },
        (error) => {
          console.log(error);
          this.loading_google = false;
          this.error_msg = {
            msg:
              (error.response && error.response.data.message) || error.message,
            type: "Danger",
          };
        }
      );
    },
    loginFacebook() {
      this.loading_facebook = true;
      this.$store.dispatch("auth/loginFacebook").then(
        (response) => {
          this.loading_facebook = false;
          // console.log(response);
          if (response.data.url) {
            window.location.href = response.data.url;
          }
        },
        (error) => {
          console.log(error);
          this.loading_facebook = false;
          this.error_msg = {
            msg:
              (error.response && error.response.data.message) || error.message,
            type: "Danger",
          };
        }
      );
    },
  },
  mounted() {
    if (this.$store.state.auth.loggedIn) {
      this.$router.push({ name: "Cycles" });
    }
    document.title = this.$i18n.t("auth.login");
  },
};
</script>
